import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
  NumberField,
  DateField,
} from 'react-admin';
import { Card, CardContent } from '@mui/material';

export const PostFilterSidebarPicanovaOrders = () => {
    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 300 }}>
            <CardContent>
                <Filter>
                    <TextInput label="Error" source="error" alwaysOn />
                </Filter>
            </CardContent>
        </Card>
    );
};

const PicanovaOrderList = props => (
    <List {...props} aside={<PostFilterSidebarPicanovaOrders/>}>
        <Datagrid>
            <NumberField source="id" label="Order ID" />
            <NumberField source="taskId" label="Task ID" />
            <TextField source="order" label="OrderData" />
            <TextField source="error" label="Error Message" />
            <DateField source="createdAt" label="Created At" />
            <DateField source="updatedAt" label="Updated At" />
            <NumberField source="Task.id" label="Task ID (Nested)" />
            <TextField source="Task.language" label="Language" />
            <TextField source="Task.email" label="Task Email" />
            <TextField source="Task.name" label="Task Name" />
        </Datagrid>
    </List>
);

export default PicanovaOrderList;