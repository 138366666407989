import { List } from "react-admin";

const metadata = {
    age: {
        Early_Adulthood_17_20: 6,
        Young_Adulthood_21_26: 7,
        Thirties_27_34: 8,
        Middle_Age_35_40: 9,
        Late_Middle_Age_41_50: 10,
        Early_Senior_Years_51_60: 11,
        Elderly_61_plus: 12
    },
    gender: {
        Woman: 0,
        Man: 1
    },
    style: {
        Photorealistic: 0
    },
    ethnicity: {
        Afro: 5,
        Caucasian: 6,
        Latino_Hispanic: 7,
        East_Asian: 8,
        Southeast_Asian: 9,
        Indian: 10,
        Middle_Eastern: 11
    }
};

export const MetadataListLinkedInPhoto = () => {

    return (
        <List>
            <table>
                <thead>
                    <tr>
                        <th>Field</th>
                        <th>Values</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(metadata).map(([field, values]) => (
                        <tr key={field}>
                            <td>{field}</td>
                            <td>
                                <ul>
                                    {Object.entries(values).map(([label, value]) => (
                                        <li key={label}>
                                            {label}: {value}
                                        </li>
                                    ))}
                                </ul>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </List>
    );
};