// src/components/TaskList.js
import {
    List,
    Datagrid,
    TextField,
    ImageField,
    FunctionField,
} from 'react-admin';
import CustomImage from './MyOrders';
import React from 'react';
import { Card, CardContent } from '@mui/material';
import { Filter, TextInput } from 'react-admin';


export const PostFilterSidebarAllTasks = () => {
    // ... (rest of the existing code)

    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
            <CardContent>
                <Filter>
                    <TextInput label="State" source="state" alwaysOn />
                </Filter>
            </CardContent>
        </Card>
    );
};

export const AllTasksLists = props => (
    <List {...props} bulkActionButtons={false} aside={<PostFilterSidebarAllTasks />}>
        <Datagrid>
            <TextField source="id" />
            <TextField source="name" />
            <TextField source="email" />
            <TextField source="phone" />
            <TextField source="variant_code" />
            <TextField source="variant_name" />
            <TextField source="variant_height" />
            <TextField source="variant_width" />
            <TextField source='state' />
            <FunctionField
                source="base_image"
                label="Image"
                render={record => <CustomImage url={record.base_image} />}
            />
            <ImageField
                source="base_model"
                title="Item Image"
                label="Item Image"
            />
            <FunctionField
                source="final_result"
                label="Image"
                render={record => <CustomImage url={record.final_result} />}
            />

        </Datagrid>
    </List>
);