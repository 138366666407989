import axios from 'axios';

const API_URL = 'https://api.storyface.ai/api';

const dataProvider = {
  getList: async (resource, params) => {
    let url = `${API_URL}/${resource}`;
    if (resource === "images/classify") {
      if (params.filter.id) {
        url = `${API_URL}/images/filter?theme=${-1}&style=${-1}&gender=${-1}&age=${-1}&ethnicity=${-1}&page=${0}&items=${10000}&app=${params.filter.app || -1}&imageId=${params.filter.id}`;
      }
      else {
        url = `${API_URL}/images/filter?theme=${params.filter.theme || -1}&style=${params.filter.style || -1}&gender=${params.filter.gender || -1}&age=${params.filter.age || -1}&ethnicity=${params.filter.ethnicity || -1}&page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 3000}&app=${params.filter.app || -1}`;
      }
    }
    if (resource === "images/unclassified") {
      if (params.filter.theme) {
        url = `${API_URL}/${resource}?page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 10000}&folder=${params.filter.theme}`;
      }
      else {
        url = `${API_URL}/${resource}?page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 10000}`;
      }
    }
    if (resource === "metadata/storyface" || resource === "metadata/linkedinphoto") {
      url = `${API_URL}/themes/metadata`
    }
    if (resource === "task/pending") {
      url = `${API_URL}/${resource}/?page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 10000}`;
    }
    if (resource === "all_tasks") {
      url = `${API_URL}/task/?state=${params.filter.state || 0}&page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 10000}`;
    }
    if (resource === "picanova_orders") {
      url = `${API_URL}/admin/picanovaorders/?error=${params.filter.error || 0}&page=${params.pagination.page - 1 || 0}&items=${params.pagination.perPage || 10000}`;
    }

    const token = localStorage.getItem('token');
    const response = await axios.get(url, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.status < 200 || response.status >= 300) {
      //throw new Error(response.statusText);
      return {
        data: [], total: 0,
      }
    }

    if (resource === 'themes/metadata') {
      // assuming your metadata object is in the response body
      // and it's structured as {"age":{}, "gender":{}, "style":{}}
      const data = Object.keys(response.data).map(key => ({
        id: key,
        value: response.data[key]
      }));

      return {
        data,
        total: data.length,
      };
    }

    if (resource === 'images/unclassified') {
      // assuming your images object is in the response body
      const data = Object.keys(response.data.data).reduce((acc, themeKey) => {
        response.data.data[themeKey].forEach((img, index) => {
          acc.push({
            id: `${themeKey}-${index}-${params.pagination.page}-${params.pagination.perPage}-${params.filter.theme}`,
            theme: themeKey,
            imgName: img
          });
        });
        return acc;
      }, []);

      return {
        data,
        total: response.data.page.total * response.data.page.size,
      };
    }

    if (resource === "images/classify") {
      // Check if params.filter.id is not empty, if it's not empty, filter the data
      let dataFromResponse = response.data.data;
      if (params.filter && params.filter.id) {
        dataFromResponse = dataFromResponse.filter(record => record.id.toString() === params.filter.id);
      }

      const data = dataFromResponse.map((record) => ({
        id: record.id,
        image: `https://api.storyface.ai/api/images/${record.id}/200/0`, // assuming the 'id' can be used to fetch the image and the width is 800
        style: record.style,
        gender: record.gender,
        age: record.age,
        ethnicity: record.ethnicity,
        themes: record.themes.map(theme => theme.name).join(', '),
      }));
      const total = response.data.page.total * response.data.page.size;

      return {
        data,
        total,
      };
    }

    if (resource === 'picanova_orders') {
      const { data } = response;
      return {
        data: data.orders.map(item => ({ ...item, id: item.id.toString() })),
        total: response.data.page.total * response.data.page.size,
      };
    }

    if (resource === "task/pending" || resource === "task" || resource === "all_tasks") {
      // Check if params.filter.id is not empty, if it's not empty, filter the data
      if (resource === "task/pending")
        var dataFromResponse = response.data.data;
      else
        dataFromResponse = response.data.data;

      if (params.filter && params.filter.id) {
        dataFromResponse = dataFromResponse.filter(record => record.id.toString() === params.filter.id);
      }

      const data = [];
      for (let i = 0; i < dataFromResponse.length; i++) {
        var temp = {
          id: dataFromResponse[i].id,
          name: dataFromResponse[i].name,
          email: dataFromResponse[i].email,
          phone: dataFromResponse[i].phone,
          state: dataFromResponse[i].state,
          base_model: `https://api.storyface.ai/api/images/${dataFromResponse[i].Image.id}/200/0`,
          base_image: `https://api.storyface.ai/api/task/face/${dataFromResponse[i].id}/200`,
          final_result: `https://api.storyface.ai/api/task/swap/${dataFromResponse[i].id}/200`
        };

        for (let j = 0; j < dataFromResponse[i].TaskItems.length; j++) {
          temp.variant_code = dataFromResponse[i].TaskItems[j].variant_code;

          if (dataFromResponse[i].TaskItems[j].Variant !== undefined) {
            temp.variant_name = dataFromResponse[i].TaskItems[j].Variant.name;
            temp.variant_height = dataFromResponse[i].TaskItems[j].Variant.printfile_height;
            temp.variant_width = dataFromResponse[i].TaskItems[j].Variant.printfile_width;
          } else {
            temp.variant_name = "-";
            temp.variant_height = "-";
            temp.variant_width = "-";
          }

        }
        data.push(temp);
      }

      const total = response.data.page.total * response.data.page.size;
      return {
        data,
        total,
      };
    }

    return {
      data: response.data.themes,
      total: response.data.themes.length,
    };
  },

  getOne: async (resource, params) => {
    let url = `${API_URL}/${resource}`;
    let inputString = params.id;
    const [, , page, perPage, folder] = inputString.split("-");
    if (resource === "images/classify") {
      if (params.id) {
        url = `${API_URL}/images/filter?theme=${-1}&style=${-1}&gender=${-1}&age=${-1}&ethnicity=${-1}&page=${0}&items=${10000}&app=${params.app || -1}&imageId=${params.id}`;
      }
      else {
        url = `${API_URL}/images/filter?theme=${-1}&style=${-1}&gender=${-1}&age=${-1}&ethnicity=${-1}&page=${0}&items=${10000}&app=${params.app || -1}`;
      }
    }
    if (resource === "images/unclassified") {
      if (folder === "undefined") {
        url = `${API_URL}/${resource}?page=${page - 1 || 0}&items=${perPage || 5000}`;
      }
      else {
        url = `${API_URL}/${resource}?page=${page - 1 || 0}&items=${perPage || 5000}&folder=${folder}`;
      }
    }

    const token = localStorage.getItem('token');
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    let data;
    if (resource === "themes") {
      data = response.data.themes.find((theme) => Number(theme.id) === Number(params.id));
    }

    if (resource === "task"){
      return {data: {id: params.id, src: `https://api.storyface.ai/api/task/swap/${params.id}/400`}}
    }

    if (resource === "images/unclassified") {
      // Find the required image from the list of images returned
      data = Object.keys(response.data.data).reduce((acc, themeKey) => {
        response.data.data[themeKey].forEach((img, index) => {
          acc.push({
            id: `${themeKey}-${index}-${page}-${perPage}-${folder}`,
            theme: themeKey,
            imgName: img,
            image: `https://api.storyface.ai/api/images/unclassified/${themeKey}/${img}/400`
          });
        });
        return acc;
      }, []);
      data = data.find((theme) => theme.id === params.id);
    }

    if (resource === "images/classify") {
      data = response.data.data.map((record) => ({
        id: record.id,
        image: `https://api.storyface.ai/api/images/${record.id}/1090/0`,
        style: record.style,
        gender: record.gender,
        age: record.age,
        ethnicity: record.ethnicity,
        theme: record.themes.map(theme => theme.id),
        app: record.app,
      }));
      data = data.find((theme) => Number(theme.id) === Number(params.id));
    }

    if (!data) {
      throw new Error('Element not found!');
    }

    return { data: data };
  },

  getMany: async (resource, params) => {
    const token = localStorage.getItem('token');
    const url = `${API_URL}/${resource}`;
    const response = await axios.post(url, {
      ids: params.ids,
    },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return { data: response.data };
  },

  getManyReference: async (resource, params) => {
    // Implement this according to your API's specifics
    // Similar to getMany
  },

  create: async (resource, params) => {
    const token = localStorage.getItem('token');
    let url = `${API_URL}/${resource}/add`;
    if (resource === 'images/classify') {
      url = `${API_URL}/images/uploadimage`;

      let formData = new FormData();
      formData.append('image', params.data.image.rawFile);
      formData.append('theme', params.data.theme);
      formData.append('style', params.data.style);
      formData.append('gender', params.data.gender);
      formData.append('age', params.data.age);
      formData.append('ethnicity', params.data.ethnicity);
      formData.append('app', params.data.app);

      const response = await axios.post(url, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return { data: { ...response.data, id: response.data.id } };

    }
    const response = await axios.post(url, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    // Use the id from params.data for the response
    return { data: { id: params.data.id, ...params.data } };
  },

  update: async (resource, params) => {
    const token = localStorage.getItem('token');
    let url = `${API_URL}/${resource}/update`;
    if (resource === 'images/unclassified') {
      url = `${API_URL}/images/classify`;

      params.data = {
        folder: params.previousData.theme,
        image: params.previousData.imgName,
        theme: params.data.themes,
        style: params.data.style,
        gender: params.data.gender,
        age: params.data.age,
        ethnicity: params.data.ethnicity,
        app: params.data.app
      };
    }

    if (resource === "task"){
      url = `${API_URL}/${resource}/update_swapface`
      console.log(params)
      let formData = new FormData();
      formData.append('image', params.data.image.rawFile);
      formData.append('taskId', params.id);

      const response = await axios.put(url, formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return { data: { ...response.data, id: response.data.id } };
    }

    if (resource === 'images/classify') {
      url = `${API_URL}/images/update`;

      params.data = {
        imgId: params.id,
        theme: params.data.theme,
        style: params.data.style,
        gender: params.data.gender,
        age: params.data.age,
        ethnicity: params.data.ethnicity,
        app: params.data.app
      };
    }

    const response = await axios.put(url, params.data, {
      headers: {
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return { data: response.status };
  },

  updateMany: async (resource, params) => {
    const token = localStorage.getItem('token');

    const updatePromises = params.ids.map(id =>
      axios({
        url: `${API_URL}/${resource}/update`,
        method: 'PUT',
        data: { id, ...params.data },
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
    );

    await Promise.all(updatePromises);

    return { data: params.ids };
  },

  delete: async (resource, params) => {
    const token = localStorage.getItem('token');
    let url = `${API_URL}/${resource}/delete`;
    if (resource === 'images/classify') {
      url = `${API_URL}/images`;
    }
    const response = await axios.delete(url, { data: { id: params.id } },
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    if (response.status < 200 || response.status >= 300) {
      throw new Error(response.statusText);
    }

    return { data: response.status };
  },

  deleteMany: async (resource, params) => {
    const token = localStorage.getItem('token');
    let url = `${API_URL}/${resource}/delete`
    if (resource === 'images/classify') {
      url = `${API_URL}/images`;
    }
    const deletePromises = params.ids.map(id =>
      axios({
        url: url,
        method: 'DELETE',
        data: { id },
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      })
    );

    await Promise.all(deletePromises);

    return { data: params.ids };
  },

  ejectTast: async (resource, type, params) => {
    const token = localStorage.getItem('token');
    const url = `${API_URL}/${resource}`;
    const response = await axios[type](url, params,
      {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

    if (response.status < 200 || response.status >= 300) {
      //throw new Error(response.statusText);
    }

    return { data: response.data };
  },
};

export default dataProvider;
