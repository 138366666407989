import React, { useState, useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField,
    Button,
    useRecordContext,
    useRefresh,
    ImageField,
    FunctionField,
    Edit, SimpleForm, NumberInput, ImageInput
} from "react-admin";
import dataProvider from '../dataProvider';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { EditButton } from 'react-admin';
// import UploadIcon from '@mui/icons-material/Upload';
// import Card from '@mui/material/Card';
// import CardHeader from '@mui/material/CardHeader';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import Typography from '@mui/material/Typography';
// import Avatar from '@mui/material/Avatar';

// import { Modal } from '@mui/material';

const CustomImage = ({ url }) => {
    const [imageUrl, setImageUrl] = useState(null);

    useEffect(() => {
        // Function to fetch the image as a blob and convert to a local object URL
        const fetchImage = async () => {
            try {
                const token = localStorage.getItem('token'); // Retrieve the token from local storage or from wherever you've stored it

                const response = await fetch(url, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    }
                });

                if (response.ok) {
                    const imageBlob = await response.blob();
                    const localUrl = URL.createObjectURL(imageBlob);
                    setImageUrl(localUrl);
                } else {
                    console.error("Error fetching image");
                }
            } catch (err) {
                console.error("Error:", err);
            }
        };

        fetchImage();
    }, [url]);

    if (!imageUrl) return null; // or return a placeholder or loader

    return <img src={imageUrl} alt="Custom Fetch" />;
};

export default CustomImage;

const UnassignButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleClick = async () => {
        var params = {
            id: record.id,
        };
        await dataProvider.ejectTast('task/unassign', 'put', params);
        refresh();
    };

    return (
        <Button onClick={handleClick} className={"action-buttons"}>
            <RemoveCircleIcon />
        </Button>
    );
};

const OkButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleClick = async () => {
        var params = {
            taskId: record.id,
        };
        try{
            await dataProvider.ejectTast('task/approve', 'put', params);
        }
        catch{
            
        }
        refresh();
    };

    return (
        <Button onClick={handleClick} className={"action-buttons"}>
            <CheckCircleIcon />
        </Button>
    );
};

// const EditarButton = () => {
//     const record = useRecordContext();
//     const refresh = useRefresh();

//     const [open, setOpen] = useState(false);
//     const [selectedFile, setSelectedFile] = useState(null);
//     const [error, setError] = useState("");
//     const [uploading, setUploading] = useState(false);

//     const handleFileChange = (event) => {
//         setSelectedFile(event.target.files[0]);
//         setError("");
//     };

//     const handleOpen = () => {
//         setOpen(true);
//         setError("");
//     };

//     const handleClose = () => {
//         setOpen(false);
//         setError("");
//     };

//     const style = {
//         position: 'absolute',
//         top: '50%',
//         left: '50%',
//         transform: 'translate(-50%, -50%)',
//         width: 400,
//         bgcolor: 'background.paper',
//         border: '2px solid #000',
//         boxShadow: 24,
//         pt: 2,
//         px: 4,
//         pb: 3,
//     };

//     const handleUpload = async () => {
//         if (selectedFile) {
//             const formData = new FormData();
//             formData.append('taskId', record.id);
//             formData.append('image', selectedFile);
//             await dataProvider.ejectTast('task/update_swapface', 'put', formData);
//             refresh();
//             setUploading(false);
//         } else {
//             setError("You must upload an image");
//         }
//     };

//     return (
//         <React.Fragment>
//             <Modal open={open} onClose={handleClose}>
//                 <Card sx={{ ...style, width: 400 }}>
//                     <CardHeader
//                         avatar={
//                             <Avatar sx={{ bgcolor: '#2196f3' }} aria-label="recipe">
//                                 <UploadIcon />
//                             </Avatar>
//                         }
//                         title="Upload the correct image"
//                     />
//                     <CardContent>
//                         <Typography variant="body2" color="text.secondary">
//                             <input type="file" accept="image/*" onChange={handleFileChange} />
//                             <p className={"error"}>{error}</p>
//                             {uploading}
//                         </Typography>
//                     </CardContent>

//                     <CardActions>
//                         <Button className={"btn-primary"} size="small" onClick={handleUpload}>Upload</Button>
//                         <Button className={"btn-secondary"} size="small" onClick={handleClose}>Close</Button>
//                     </CardActions>
//                 </Card>
//             </Modal>
//             <Button onClick={handleOpen} className={"action-buttons"}>
//                 <UploadIcon />
//             </Button>
//         </React.Fragment>
//     );
// };

const MisActions = () => {
    const record = useRecordContext();

    if (record.state !== 3) {
        return (
            <div className={"datagrid-action"}>
                <OkButton />
                <EditButton/>
                <UnassignButton />
            </div>
        );
    } else {
        return (
            <div className={"datagrid-action"}> - </div>
        );
    }

};

export const MyOrdersList = () => {
    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="variant_code" />
                <TextField source="variant_name" />
                <TextField source="variant_height" />
                <TextField source="variant_width" />
                <FunctionField
                    source="base_image"
                    label="Base Image"
                    render={record => <CustomImage url={record.base_image} />}
                />
                <ImageField
                    source="base_model"
                    title="Item Image"
                    label="Item Image"
                />
                <FunctionField
                    source="final_result"
                    label="Final Result"
                    render={record => <CustomImage url={record.final_result} />}
                />
                <MisActions source="Actions" />
            </Datagrid>
        </List>
    );
};

export const MyOrdersEdit = () => {
    return (
        <Edit>
            <SimpleForm>
                <NumberInput disabled source="id" />
                <FunctionField
                    source="preview"
                    label="Final Result"
                    render={record => <CustomImage url={record.src} />}
                />
                <ImageInput source="image" label="Image">
                    <ImageField source='src'/>
                </ImageInput>
            </SimpleForm>
        </Edit>
    );
};
