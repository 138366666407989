import {
  defaultTheme,
  Layout,
  AppBar,
  ToggleThemeButton,
  TitlePortal,
} from "react-admin";

const CustomAppBar = () => (
  <AppBar>
    <TitlePortal />
    <ToggleThemeButton lightTheme={defaultTheme}/>
  </AppBar>
);

export const AppLayout = (props) => <Layout {...props} appBar={CustomAppBar} />;
