import {
    List,
    Datagrid,
    TextField,
    Button,
    useRecordContext,
    useRefresh,
    ImageField,
    FunctionField
} from "react-admin";
import dataProvider from '../dataProvider';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import CustomImage from "./MyOrders";

const SelectButton = () => {
    const record = useRecordContext();
    const refresh = useRefresh();

    const handleClick = async () => {
        var params = {
            id: record.id,
        };
        await dataProvider.ejectTast('task/assign', 'put', params);
        refresh();
    };

    return (
        <Button onClick={handleClick} className={"action-buttons"}>
            <ControlPointIcon />
        </Button>
    );
};

export const OrdersList = () => {
    return (
        <List>
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="variant_code" />
                <TextField source="variant_name" />
                <TextField source="variant_height" />
                <TextField source="variant_width" />
                <FunctionField 
                    source="base_image" 
                    label="Image" 
                    render={record => <CustomImage url={record.base_image} />} 
                />
                <ImageField
                    source="base_model"
                    title="Item Image"
                    label="Item Image"
                />
                <FunctionField 
                    source="final_result" 
                    label="Image" 
                    render={record => <CustomImage url={record.final_result} />} 
                />
                <SelectButton source="take_task" />
            </Datagrid>
        </List>
    );
};
