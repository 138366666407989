import { List } from "react-admin";

const metadata = {
  age: {
    Baby: 1,
    Young: 2,
    Adult: 3
  },
  gender: {
    Woman: 0,
    Man: 1
  },
  style: {
    Photorealistic: 0
  },
  ethnicity: {
    Afro: 1,
    Asian: 2,
    Caucasian: 3
  }
};

export const MetadataListStoryFace = () => {

  return (
    <List>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Values</th>
          </tr>
        </thead>
        <tbody>
          {Object.entries(metadata).map(([field, values]) => (
            <tr key={field}>
              <td>{field}</td>
              <td>
                <ul>
                  {Object.entries(values).map(([label, value]) => (
                    <li key={label}>
                      {label}: {value}
                    </li>
                  ))}
                </ul>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </List>
  );
};
