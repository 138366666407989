import { Admin, Resource } from "react-admin";
import authProvider from "./authProvider";
import { ThemeCreate, ThemeEdit, ThemeList } from "./components/Theme";
import ArticleIcon from "@mui/icons-material/Article";
import { AppLayout } from "./layouts/AppLayout";
import dataProvider from "./dataProvider";
import { UnclassifiedImageEdit, UnclassifiedImageList } from "./components/UnclassifiedImage";
import { ImageClassifyCreate, ImageClassifyEdit } from "./components/ClassifiedImage";
import { ImageClassifyList } from "./components/ClassifiedImage";
import Dashboard from "./Dashboard";
import { MetadataListStoryFace } from "./components/StoryFaceMetadata";
import { MetadataListLinkedInPhoto } from "./components/LinkedInPhotoMetadata";
import { OrdersList } from "./components/Orders";
import {MyOrdersEdit, MyOrdersList } from "./components/MyOrders";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { AllTasksLists } from "./components/AllTasksList";
import PicanovaOrderList from "./components/PicanovaOrders";

function App() {
  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      layout={AppLayout}
    >
      <Resource
        name="themes"
        list={ThemeList}
        create={ThemeCreate}
        edit={ThemeEdit}
        icon={ArticleIcon}
      />
      <Resource
        name="metadata/storyface"
        list={MetadataListStoryFace}
        icon={ArticleIcon}
      />
      <Resource
        name="metadata/linkedinphoto"
        list={MetadataListLinkedInPhoto}
        icon={ArticleIcon}
      />
      <Resource
        name="images/unclassified"
        list={UnclassifiedImageList}
        edit={UnclassifiedImageEdit}
      />
      <Resource
        name="images/classify"
        list={ImageClassifyList}
        edit={ImageClassifyEdit}
        create={ImageClassifyCreate}
      />
      <Resource
        name="task/pending"
        list={OrdersList}
        icon={ReceiptLongIcon}
        delete={false}
      />
      <Resource
        name="task"
        list={MyOrdersList}
        edit={MyOrdersEdit}
        icon={ListAltIcon}
        delete={false}
      />
      <Resource name="all_tasks" list={AllTasksLists} />
      <Resource name="picanova_orders" list={PicanovaOrderList} />
    </Admin>

  );
}

export default App;
