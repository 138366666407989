import React from 'react';
import { List, Datagrid, TextField, NumberInput, TextInput, SimpleForm, Edit, FunctionField} from 'react-admin';
import { ArrayInput } from 'react-admin'; 
import { SimpleFormIterator } from 'react-admin';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { FilterList, FilterListItem } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CategoryIcon from '@mui/icons-material/LocalOffer';

export const PostFilterSidebar = () => {
    const [themes, setThemes] = useState([]);

    useEffect(() => {
        axios.get('https://api.storyface.ai/api/themes')
            .then(response => {
                setThemes(response.data.themes);
            })
            .catch(err => {
                console.log(err);
            });
    }, []);

    return (
    <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
        <CardContent>
            <FilterList label="Theme" icon={<CategoryIcon />}>
                    {themes.map(theme => 
                        <FilterListItem label={theme.name} value={{ theme: theme.name }} key={theme.id} />
                    )}
            </FilterList>
        </CardContent>
    </Card>
);
}

export const UnclassifiedImageList = (props) => (
    <List {...props} aside={<PostFilterSidebar />}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="theme"/>
            <TextField source="imgName" />
        </Datagrid>
    </List>
);

export const UnclassifiedImageEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput disabled source="id" />
                <TextInput disabled source="theme" />
                <TextInput disabled source="imgName" />
                <FunctionField
                    source="image"
                    render={record => 
                        <img 
                            src={record.image} 
                            alt={record.imgName || "unclassified image"}
                            style={{width: '400px', height: '400px'}}
                        />}
                />
                <ArrayInput source="themes">
                    <SimpleFormIterator>
                        <NumberInput />
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput source="style" />
                <NumberInput source="gender" />
                <NumberInput source="age" />
                <NumberInput source="ethnicity" />
                <NumberInput source="app" />
            </SimpleForm>
        </Edit>
    );
};