import axios from 'axios';

const API_URL = 'https://api.storyface.ai/api';

const authProvider = {
    login: async ({ username, password }) => {
        const response = await axios.post(`${API_URL}/auth/login`, {
            email: username,
            password: password,
        });

        if (response.status < 200 || response.status >= 300) {
            throw new Error(response.statusText);
        }
        
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('refreshToken', response.data.refreshToken);  // assuming the server sends back a refresh token
    },
    logout: () => {
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        return Promise.resolve();
    },
    checkError: async (error) => {
        if (error.status === 401) {
            const { refreshToken } = localStorage;

            if (!refreshToken) {
                return Promise.reject();
            }

            try {
                const response = await axios.post(`${API_URL}/auth/refresh`, { refreshToken });  // assuming you have an endpoint for refreshing the token

                localStorage.setItem('token', response.data.token);
                localStorage.setItem('refreshToken', response.data.refreshToken);  // update the refresh token

                return Promise.resolve();
            } catch (e) {
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                return Promise.reject();
            }
        }

        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('token')
            ? Promise.resolve()
            : Promise.reject();
    },
    getPermissions: () => {
        return Promise.resolve();
    },
};

export default authProvider;
