import React from 'react';
import { Edit, SimpleForm, TextInput, NumberInput, NumberField, Datagrid, TextField, List, ImageInput } from 'react-admin';
import { ImageField } from 'react-admin';
import { Create } from 'react-admin';
import { ArrayInput } from 'react-admin';
import { SimpleFormIterator } from 'react-admin';
import { FunctionField } from 'react-admin';
import { FilterList, FilterListItem, Filter } from 'react-admin';
import { Card, CardContent } from '@mui/material';
import CategoryIcon from '@mui/icons-material/LocalOffer';
import axios from 'axios';
import { useState, useEffect } from 'react';


export const ImageClassifyEdit = (props) => {
  return (
      <Edit {...props}>
          <SimpleForm>
              <TextInput disabled source="id" />
              <FunctionField
                    source="image"
                    render={record => 
                        <img 
                            src={record.image} 
                            alt={record.id}
                            style={{width: '400px', height: '400px'}}
                        />}
                />
              <ArrayInput source="theme">
                  <SimpleFormIterator>
                      <NumberInput />
                  </SimpleFormIterator>
              </ArrayInput>
              <NumberInput source="style" />
              <NumberInput source="gender" />
              <NumberInput source="age" />
              <NumberInput source="ethnicity" />
              <NumberInput source="app" />
          </SimpleForm>
      </Edit>
  );
};

export const PostFilterSidebar = () => {
    const [themes, setThemes] = useState([]);
    const [ages, setAges] = useState([]);
    const [ethnicities, setEthnicities] = useState([]);
    const [appId, setAppId] = useState(0); // initialize to default value
    
    useEffect(() => {
        axios.get('https://api.storyface.ai/api/themes')
            .then(response => setThemes(response.data.themes))
            .catch(err => console.log(err));
    }, []);
    
    useEffect(() => {
        if(appId !== 0) {
            axios.get(`https://api.storyface.ai/api/ages?app=${appId}`)
                .then(response => setAges(response.data.ages))
                .catch(err => console.log(err));
            
            axios.get(`https://api.storyface.ai/api/ethnicities?app=${appId}`)
                .then(response => setEthnicities(response.data.ethnicities))
                .catch(err => console.log(err));
        }
    }, [appId]);

    const handleAppIdChange = (event) => {
        setAppId(event.target.value);
    }

    return (
        <Card sx={{ order: -1, mr: 2, mt: 9, width: 200 }}>
            <CardContent>
                <Filter>
                    <TextInput label="ID" source="id" alwaysOn />
                </Filter>
                <Filter>
                    <TextInput label="App ID" source="app" alwaysOn onChange={handleAppIdChange} />
                </Filter>
                <FilterList label="Theme" icon={<CategoryIcon />}>
                    {themes.map(theme => 
                        <FilterListItem label={theme.name} value={{ theme: theme.id }} key={theme.id} />
                    )}
                </FilterList>
                <FilterList label="Gender" icon={<CategoryIcon />}>
                    <FilterListItem label="Woman" value={{ gender: 0 }} />
                    <FilterListItem label="Man" value={{ gender: 1 }} />
                </FilterList>
                <FilterList label="Style" icon={<CategoryIcon />}>
                    <FilterListItem label="Photorealistic" value={{ style: 0 }} />
                </FilterList>
                <FilterList label="Age" icon={<CategoryIcon />}>
                    {ages.map(age => 
                        <FilterListItem label={age.name} value={{ age: age.id }} key={age.id} />
                    )}
                </FilterList>
                
                <FilterList label="Ethnicity" icon={<CategoryIcon />}>
                    {ethnicities.map(ethnicity => 
                        <FilterListItem label={ethnicity.name} value={{ ethnicity: ethnicity.id }} key={ethnicity.id} />
                    )}
                </FilterList>
                </CardContent>
        </Card>
    );
};

export const ImageClassifyList = (props) => (
  <List {...props} aside={<PostFilterSidebar />}>
      <Datagrid rowClick="edit">
          <TextField source="id" />
          <ImageField source='image' title="image" />
          <NumberField source="style" />
          <NumberField source="gender" />
          <NumberField source="age" />
          <NumberField source="ethnicity" />
          <TextField source='themes'/>
      </Datagrid>
  </List>
);

export const ImageClassifyCreate = props => {
    return (
        <Create {...props} title="Upload an image">
            <SimpleForm>
                <ImageInput source="image" label="Image">
                    <ImageField source='src'/>   
                </ImageInput>
                <ArrayInput source="theme">
                    <SimpleFormIterator>
                        <NumberInput label="Theme ID" />
                    </SimpleFormIterator>
                </ArrayInput>
                <NumberInput source="style" />
                <NumberInput source="gender" />
                <NumberInput source="age" />
                <NumberInput source="ethnicity" />
                <NumberField source="app" />
            </SimpleForm>
        </Create>
    );
};
